<template>
    <div
        class="property"
        @mouseover="hover = true"
        @mouseleave="windowWidth > 641 ? hover = false : hover = true"
        >
        <router-link :to="url" :aria-label="`Go to property ${address}`">
            <img v-lazy="img" :alt="address">
        </router-link>
        <span :class="status">{{status | startCase}}</span>
        <transition 
            name="fadeInRight"
            enter-active-class="animated faster fadeIn"
            leave-active-class="animated faster fadeOut">
            <div v-if="hover" class="property_data">
                <h3>{{address}}</h3>
                <div class="detail">
                    <p>{{price | priceFormat}}</p>
                    <p class="middle">{{beds}} {{$t('message.homeproperty.beds')}}</p>
                    <p class="last">{{baths}} {{$t('message.homeproperty.baths')}}</p>
                </div>
                <router-link class="btn_link btn" :to="url">
                    {{$t('message.homeproperty.view')}}
                </router-link>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "SingleProperty",
    props:['status','img','url','address','price','beds','baths'],
    data(){
        return{
            hover: false,
        }
    },
    mounted:function(){
        if(this.windowWidth < 641){
            this.hover = true;
        }
    }
    
}
</script>

<style lang="scss" scoped>
.property{
    max-height: 500px;
    height: 65vh;
    width: 49%;
    position: relative;
    padding: 0 5px;
    margin: 5px 0;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    span{
        position: absolute;
        bottom:20px;
        left:20px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.8rem;
        padding:2px 15px;
        letter-spacing: 1.2px;
        &.available, &.comingSoon{
            background: coral;
            color: #fff;
        }
        &.sold{
            color: #fff;
            background: #000;
        }
        &.pending{
            background: #fff;
        }
        &.rented{
            color: #fff;
            background: #000;
        }
    }
    .property_data{
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50%;
        text-align: center;
        background:rgba(255,255,255,.7);
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 0.85rem;
        h3{
            font-weight: 400;
            margin: 0.5rem 2rem;
            word-wrap: break-word;
            font-size: 1.25rem;
        }
        .detail{
            p{
                margin: 4px 0;
                padding: 10px 30px;
                text-transform: uppercase;
                font-weight: 600;
                &.middle{
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                }
            } 
        }
    }
}
@media (max-width: $tablet) {
    .property{
        height: 300px;
        img {
            height: 100%;
        }
    }
}
@media (max-width: $mobile) {
    .property{
        width: 100%;
        height: 300px;
        margin: 10px 0;
        position: relative;
        img {
            display: block;
            width: 100%;
            height: 220px;
        }
        span{
            position: absolute;
            bottom:100px;
            left:50%;
            transform: translateX(-50%);
            text-transform: uppercase;
            font-weight: 700;
            font-size: 0.8rem;
            padding:2px 15px;
            letter-spacing: 1.2px;
            &.available{
                background: coral;
                color: #fff;
            }
            &.sold{
                color: #fff;
                background: #000;
            }
            &.pending{
                background: #fff;
            }
            &.rented{
                color: #fff;
                background: #000;
            }
        }
        .property_data{
            display:block;
            position: relative;
            top: 0;
            right: 0;
            height: 100px;
            width: 100%;
            text-align: center;
            background:#f5f5f5;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h3{
                font-family: $secondaryFont;
                font-weight: 400;
                margin: 0.5rem 0;
            }
            .detail{
                p{
                    margin: 0;
                    padding: 0 10px;
                    &.middle{
                        display: none;
                    }
                    &.last{
                        display: none;
                    }
                } 
            }
            .btn_link{
                display: none;
            }
        }
    }
}
</style>