<template>
    <div>
        <div v-if="!agentData">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div v-else-if="nodatafound">
            Sorry, we can't found your data, please try to access another page.
        </div>
        <div id="agent" v-else>
            <div class="agent_info">
                <div class="agent_data">
                    <img :src="apiURL+ agentData.Image.data.attributes.url" class="agent_photo" :alt="`${agentData.FirstName} ${agentData.LastName}`" />
                    <div class="info">
                        <p class="first">{{agentData.FirstName}} {{agentData.LastName}}</p>
                        <p class="no_bold">{{agentData.Subtitle}}</p>
                        <p>{{agentData.Phone}}</p>
                        <p>{{agentData.Email}}</p>
                    </div>
                    <hr>
                </div>
                <div class="agent_bio">
                    <p v-html="agentData.Content"></p>
                </div>
            </div>
            <div class="agent_property" v-if="listings.length > 0">
                <h3>{{agentData.FirstName}}'s Properties</h3>
                <div class="properties">
                    <SingleProperty
                        v-for="(listing, i) in listings" :key="i"
                        :img="apiURL+listing.FeaturedImage.data.attributes.url"
                        :url="`/property/${listing.category}s/${listing.Slug}`"
                        :status="listing.Status"
                        :address="listing.Address"
                        :beds="listing.Bedrooms"
                        :baths="listing.Bathrooms"
                        :price="listing.Price"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner';
import SingleProperty from '../components/SingleProperty'

export default {
    name: "Agent",
    components: {
        Spinner,
        SingleProperty
    },
    data(){
        return{
            title: "Cerda-Zein | Agent",
            nodatafound: false,
            agentData: undefined,
            connections: [],
            apiURL: 'https://strapi.cerda-zein.com',
        }
    },
    created:function(){
        this.axios.get(`https://strapi.cerda-zein.com/api/agents?populate=*&filters[Slug][$eq]=${this.$route.params.slug}`)
        .then((response) => {
            if(response.data.data.length > 0){
                this.agentData = response.data.data[0].attributes;
            } else {
                this.nodatafound = !this.nodatafound
            }
        })
    },
    metaInfo() {
         let data = this.title; 
         return {
             title: data ? data : "Cerda-Zein Agent",
             titleTemplate: '%s | Cerda-Zein Agent',
             meta: [{
                name: 'description', 
                content: "Cerda-Zein"
            }]
         }
    },
    computed: {
        listings: function(){
            const properties = this.$store.getters.properties;
            let allProperties = [];
            for(const l of this.agentData.listings.data){
                const p = properties.find( p => p.Slug === l.attributes.Slug);
                if(p){
                    allProperties.push(p);
                }
            }
            for(const r of this.agentData.rentals.data){
                const p = properties.find( p =>  p.Slug === r.attributes.Slug);
                if(p){
                    allProperties.push(p);
                }
            }

            console.log(allProperties)
            function sortHelper( a, b ) {
                if ( a.Status < b.Status ){
                    return -1;
                }
                else if (  a.Status > b.Status){
                    return 1;
                }
                else {
                    return a.Price < b.Price ? 1 : -1;
                }
            }
            allProperties.sort(sortHelper);
            console.log(allProperties)
            return allProperties;
        }
    }
}
</script>

<style lang="scss" scoped>
.agent_info{
    display: flex;
    align-items: center;
    .agent_data{
        width: 35%;
        text-align: right;
        img{
            width: 100%;
        }
        .info{
            margin: 2rem 0;
            p{
                margin: 12px 0;
                font-weight: 600;
                text-transform: uppercase;
                opacity: 0.7;
                &.no_bold{
                    font-weight: 400;
                }
            }
        }
        hr{
            width: 80%;
            float:right;
            opacity: 0.4;
        }
    }
    .agent_bio{
        flex: 1;
        margin: 0 4rem;
        opacity: 0.7;
        line-height: 1.5rem;
    }
}

.agent_property{
    margin: 5rem 0;
    h3{
        text-align: center;
        font-family: $secondaryFont;
        font-size: 2.5rem;
        font-weight: 400;
    }
    .properties{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: $mobile) {
    .agent_info{
        display:block;
        .agent_data{
            width: 100%;
            text-align: center;
            img{
                width: 80%;
            }
            .info{
                margin: 2rem 0;
            }
            hr{
                float:none;
            }
        }
        .agent_bio{
            margin: 2rem 2rem;
        }
    }
    .agent_property{
        margin: 3rem 0;
        .properties{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
</style>